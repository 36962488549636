import React from "react"
import { graphql } from "gatsby"
import PageTemplate from './page'
import Featured from "../components/featured"

//<a href="javascript:gaOptout();">Deactivate Google Analytics</a>

const FeaturedTemplate = ({
    location,
    data
}) => {
    return (
        <>
            <PageTemplate data={data} location={location}>
                <Featured location={location} pages={data.allMarkdownRemark.nodes} />
            </PageTemplate>

        </>
    )
}

export const query = graphql`
  query FeaturedStartpageTemplateQuery(
    $id: String!
  ) {
    site {
        siteMetadata {
          title
        }
      }
      markdownRemark(id: { eq: $id }) {
        id
        excerpt(pruneLength: 160)
        htmlAst
        frontmatter {
          title
          description
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
        }
      }
    allMarkdownRemark(
        sort: { fields: [frontmatter___index], order: ASC }
        filter: {
            frontmatter: { index: { gt: -1 } }
            fields: { type: { eq: "pages" } }
        }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          index
        }
      }
    }
  }
`

export default FeaturedTemplate